import React, { useContext, useEffect } from "react"
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/logotipo.png';
import { Context } from "../../context";
import { buttons } from "./assets"
import './Home.css';

function Home() {
  const navigate = useNavigate();
  const { userName, setFooterVisible } = useContext(Context);

  useEffect(() => { setFooterVisible(true) })

  return (
    <div className="home">
      <img src={logo} alt="logo"/>

      <div className="btn text-blue" onClick={() => navigate("/register")}>
        <span>¡Comencemos!</span>
      </div>

      {userName && userName.toLowerCase() === 'admin' && (
        <>
          {buttons.map((btn) => (
            <div
              key={btn.id}
              className="btn-menu"
              onClick={() => navigate(btn.route)}
              style={{ backgroundImage: `url(${btn.image})`}}
            >
              <span> Actividad {btn.id} </span>
            </div>
          ))}

        </>
      )}

    </div>
  );
}

export default Home;
