import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes } from 'react-router-dom';

import { Context } from "../context";
import generateRoutes from '../routes/routeHelper';
import routes from '../routes';


const App = () => {
    const { userName } = useContext(Context);
  
    return (
        <Router>
            <Routes>
                {/* Public Routes */}
                {generateRoutes(routes.public, true)}
    
                {/* Private Routes */}
                {generateRoutes(routes.private, Boolean(userName))}
            </Routes>
        </Router>
    );
};


export default App;