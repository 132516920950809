import { useNavigate } from 'react-router-dom';
import { useRef, useContext } from 'react';

import imagotipo from '../../assets/imagotipo.png';
import arrow_next from '../../assets/arrow_next.png';
import logos_1 from '../../assets/intro/logos_1.png';
import useBodyColor from '../../hooks/useColors'
import { Context } from "../../context";

import './Register.css';

function Register() {
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const { setUserName } = useContext(Context);
  useBodyColor(0)

  // Virtual Keyboard height adjust 
  const height = document.documentElement.scrollHeight
  const width = document.documentElement.scrollWidth

  const handleClick = () => {
    if(width <= 768){
      document.body.style.height = `${height + 100}px`;
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleBlur = () => {
    if(width <= 768){
      document.body.style.height = `${height - 100}px`;
    }
  }

  const handleSubmit = () => {
    if(inputRef.current && inputRef.current.checkValidity()){
      localStorage.setItem('user', inputRef.current.value);
      setUserName(inputRef.current.value)
      navigate("/bienvenida");
    }
    else{
      inputRef.current.reportValidity()
    }
  }

  return (
    <div className="register container mt-3">
      <img className="imagotipo" src={imagotipo} alt="imagotipo"/>

      <h1 className="text-blue">¡Hola!</h1>
      <p className="text-blue">
        Para comenzar a jugar te pedimos que te registres con tu nombre:
      </p>

      <div className="input-group">
        <div className="row">
          <input
            required
            placeholder="Tu nombre"
            minLength="3"
            type='text'
            ref={inputRef}
            onClick={handleClick}
            onBlur={handleBlur}
          />
          <img
            src={arrow_next} 
            alt="arrow_next"
            onClick={handleSubmit}
          />
        </div>
      </div>


      <img style={{width:"75%", marginTop:"4rem"}} src={logos_1} alt="logos_1" />
      
    </div>
  );
}

export default Register;
