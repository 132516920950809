export const wondersData = [
    {
        content: "Las aves cumplen un rol ecológico importante en los ecosistemas, ya que realizan diversas funciones como por ejemplo, son dispersoras de semillas (lo que es importante para la reproducción y la variabilidad genética), son polinizadoras de plantas ayudando en la reproducción de las mismas y se encargan de reciclar nutrientes y mantener el equilibrio ecológico entre depredadores y presas, y entre plantas y herbívoros.",
        credits: "Foto: Rubén Sindoni",
        index: 0
    },
        {
        content: "El Parque General San Martín fue creado en 1896 y es el parque artificial más grande de América del Sur. Es una gran masa forestal de más de 40 mil árboles de diversas especies exóticas, funcionando como un purificador del aire de la ciudad.",
        credits: "Foto: Rubén Sindoni",
        index: 1
    },
    {
        content: "La flora tiene una gran importancia ecológica para el planeta, ya que es la base de los ecosistemas y contribuye de muchas maneras a la estabilidad del medio ambiente. Por ejemplo son productoras de oxígeno, proporcionan alimentos, contribuyen a la estabilidad del clima, ya que regulan la humedad, ayudando a mantener el equilibrio de la temperatura. Protegen el suelo, ya que las raíces de las plantas evitan la erosión, producida por el desgaste de la lluvia y el viento. Además, favorecen la conservación de la biodiversidad, proporcionando hábitats a otros organismos vivos y manteniendo el ciclo de nutrientes ya que cuando las plantas mueren, se descomponen y dejan nutrientes en el suelo que otras plantas aprovechan para su desarrollo y purifican el aire porque absorben sustancias contaminantes.",
        credits: "Foto:  Ernesto Toniolo",
        index: 2
    },
    {
        content: "Según el autor Spinoza, nuestra existencia es un sin fin de conexiones junto a otros seres vivos. Con nuestros actos y los actos de otros, con nuestras sensibilidades y las sensibilidades de otros, estamos “acá”, transformándonos, intercambiandonos, trenzados, entre las distintas expresiones de la vida, interactuando al interior de un universo que nos abarca. Lo que somos depende de lo que logra enmarañarse, de las composiciones de muchos y diversos organismos, cada uno de los cuales, está compuesto de otros organismos. Somos vida dentro de vida.",
        credits: "Foto: Rubén Sindoni",
        index: 3
    },
    {
        content: "El Cerro de la Gloria es un monumento histórico en la ciudad de Mendoza, Argentina. Conmemora la gesta libertadora del General José de San Martín y su Ejército de los Andes. Su monumento, base y entorno fueron declarados Patrimonio Cultural de la provincia de Mendoza en 1998.",
        credits: "Foto: Rubén Sindoni",
        index: 4
    },
    {
        content: "La Cordillera Frontal es el segundo cordón montañoso después de la Cordillera Principal y la Precordillera. Cumple la función de captar la humedad de las masas de aire y almacenar el agua en forma dve nieve.",
        credits: "Foto: Rubén Sindoni",
        index: 5
    },
    {
        content: "La Ciudad de Mendoza cuenta con más de 120.625 habitantes. Todos nosotros somos parte del ambiente y responsables de su cuidado. Es importante entendernos como parte de un todo ya que cada acción que realicemos como ciudadano y ciudadana cuenta.",
        credits: "Foto: Rubén Sindoni",
        index: 6
    }
];