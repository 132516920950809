import React, { useEffect, useRef } from 'react';

const CircularProgress = ({ setFirstStep, size=80, percentage = 100, duration = 28000 }) => {
  const circleRef = useRef(null);

  useEffect(() => {
    const radius = 100;
    const circumference = 2 * Math.PI * radius;

    const circle = circleRef.current;
    if (circle) {
      // Set initial dash array and offset
      circle.style.strokeDasharray = circumference;
      circle.style.strokeDashoffset = circumference;

      // Use a short delay to trigger the transition
      setTimeout(() => {
        circle.style.transition = `stroke-dashoffset ${duration}ms linear`;
        const offset = circumference - (percentage / 100) * circumference;
        circle.style.strokeDashoffset = offset;
      }, 100); // Delay to allow the transition to be applied
    }
  }, [percentage, duration]);

  setTimeout(() => setFirstStep(false), duration)

  return (
    <div style={{width:`${size}px`}}>
      <svg viewBox="0 0 250 250">
        <circle
          cx="125"
          cy="125"
          r="100"
          fill="none"
          stroke="#ddd"
          strokeWidth="40"
        />
        <circle
          ref={circleRef}
          cx="125"
          cy="125"
          r="100"
          fill="none"
          stroke="#F2AD94"
          strokeWidth="40"
          style={{
            transform: 'rotate(-90deg)',
            transformOrigin: '125px 125px',
          }}
        />
      </svg>
    </div>
  );
};

export default CircularProgress;
