import React from "react"

import Layout from "../layout"
import number_2 from '../../../assets/number_2.png';
import friends from '../../../assets/activities/activity2/friends.png';

import './Activity2.css';

function Activity2() {

  return (
    <Layout>
      <div className="activity activity-2">

        <div className="row justify-content-center mt-1">
          <img className="activity-number" style={{marginLeft:0}} src={number_2} alt="number_2"/>
        </div>
        <div className="text-center" style={{padding:"0 1rem"}}>
          <h1>
            <strong>¿QUÉ TAL LA ESCALADA?</strong>
          </h1>
          <br/>
          <h2 style={{color:"#F3657F"}}>
            ¿ESTÁS LISTO PARA EL PRÓXIMO DESAFÍO?
          </h2>
        </div> 

        <div className="col align-items-center">
          <img src={friends} className="friends-img" alt="amigos de pepe"/>
        </div>

      </div>
    </Layout>
  )
};

export default Activity2;
