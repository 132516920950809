import React from "react"
import { useNavigate } from 'react-router-dom';

import logo from '../../../assets/intro/logos_1.png';
import useBodyColor from '../../../hooks/useColors';
import Navbar from "../navbar/Navbar"

import './About.css';

function About() {
    useBodyColor(6)
    const navigate = useNavigate();

    return (
        <>
            <Navbar/>
            <div className="about text-center">

                <h2 className="text-brown">¿Quiénes somos y cuáles son los objetivos de esta propuesta lúdico motriz?</h2>
                <div style={{margin:"2rem 0"}}>
                    <p>
                        La Ciudad de Mendoza fue elegida como una de las 100 ciudades en el mundo que recibió financiamiento y asistencia técnica del Fondo de Juventud y Acción Climática de Bloomberg Philanthropies, para impulsar un movimiento juvenil de acción climática.
                    </p>
                    <br></br>
                    <p>
                        En este sentido, “Prohibido no jugar” es uno de los 14 proyectos seleccionados, por su valioso aporte en materia de educación ambiental para los jóvenes de la Ciudad.
                    </p>
                    <br></br>
                    <p>
                        “Prohibido no jugar” es una invitación a jugar, a   disfrutar de la naturaleza, a relacionarnos de una forma diferente con el entorno natural del Parque Deportivo de Montaña y los personajes que la habitan.
                    </p>

                    <br></br>

                    <h3 style={{fontSize:"1.4rem", fontWeight:"bold", marginBottom:"1rem"}}>Participaron de este proyecto:</h3>

                    <div className="team">
                        <p>
                            <span>Equipo Ecopeques</span>: Luciana Iturrieta &#8226; Paula Copo &#8226; Sergio Torres &#8226; Nicolás Quintana &#8226; Diana Estrada &#8226; Manu Rivero &#8226; Cecilia Lucca &#8226; Giselle Begnardi &#8226; Fernanda Vergne &#8226; Ernesto Toniolo
                        </p>
                        <p>
                            <span>Asesorias</span>: Hugo Ascencio (Biodiversidad) &#8226; Gabriela Precioso (Avistaje de aves) &#8226; Marisa Pérez Alonso (Corrección literaria)
                        </p>
                        <p>
                            <span>Fotos</span>: Ernesto Toniolo &#8226; Ruben Sindoni
                        </p>
                        <p>
                            <span>Diseño</span>: Griselda Agnesi
                        </p>
                        <p>
                            <span>Desarrollo</span>: Francisco Guardia 
                        </p>

                    </div>



                </div>
                <img src={logo} style={{maxWidth:"250px"}} alt="logos entidades" />

                <br></br>
                <div className="buttons">
                    <div style={{margin:"0 1.5rem"}} className="btn text-white" onClick={() => navigate('/introduccion')}>
                        <span>Volver</span>
                    </div>
                </div>

            </div>
        </>
    )
};

export default About;
