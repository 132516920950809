import number_3 from '../../../../assets/number_3.png';

import atamisque from '../../../../assets/activities/activity3/atamisque.jpg';
import chanar from '../../../../assets/activities/activity3/chanar.jpg';
import jarilla from '../../../../assets/activities/activity3/jarilla.jpg';
import llaollin from '../../../../assets/activities/activity3/llaollin.jpg';

import jarilla_shadow from '../../../../assets/activities/activity3/jarilla_shadow.png';
import atamisque_shadow from '../../../../assets/activities/activity3/atamisque_shadow.png';
import llaollin_shadow from '../../../../assets/activities/activity3/llaollin_shadow.png';
import chanar_shadow from '../../../../assets/activities/activity3/chanar_shadow.png';


export const Icons = [
    chanar_shadow,
    jarilla_shadow,
    atamisque_shadow,
    llaollin_shadow
];

export const Images = [
    chanar,
    jarilla,
    atamisque,
    llaollin
]

export { number_3 };