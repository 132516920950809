import React, { useContext, useEffect } from "react"
import { useNavigate } from 'react-router-dom';

import Navbar from "../navbar/Navbar"
import useBodyColor from '../../../hooks/useColors';
import map_icon from '../../../assets/activities/map/map_icon.png';
import './Activity5.css';

import { Context } from "../../../context";


function Activity5Alert() {
  const navigate = useNavigate();
  const { setCurrentStep } = useContext(Context);

  useBodyColor(5)
  useEffect(() => { setCurrentStep('return_short') }, [setCurrentStep]);

  return (
    <>
      <Navbar/>
      <div className="alert-return text-center">
          <h1 className="text-blue">¡ATENCIÓN!</h1>
          <h2>PUNTO DE RETORNO</h2>
          <h3 className="text-blue">En caso de querer regresar... aquí te mostramos el camino para regresar al inicio del sendero.</h3>
          <div onClick={() => navigate('/mapa')}>
            <img className="mapa" src={map_icon} alt="map_icon"/>
            <h4 className="text-blue">MAPA</h4>
          </div>
          <h5 className="text-blue" onClick={() => navigate('/actividad/5')}>Continuar aventura
          </h5>
      </div>
    </>
  )
};

export default Activity5Alert;
