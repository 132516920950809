import React from "react"

import Layout from "../layout"
import number_4 from '../../../assets/number_4.png';
import pepe from '../../../assets/pepe.png';

import './Activity4.css';

function Activity4() {

  return (
    <Layout>
      <div className="activity activity-4">

        <div className="row justify-content-center mt-1">
          <img style={{margin:'auto', width:'80px'}} src={number_4} alt="number_4"/>
        </div>
        <div className="text-info text-center">
          <p>
            GRACIAS  por atreverte a unir todas las piezas de este hermoso paisaje. Pudiste observar en su mayor esplendor al personaje principal de esta historia: 
          </p>
          <br></br>
          <h2><strong>el Pedemonte mendocino.</strong></h2> 
          <img className="pepe-img mt-2" style={{width: "200px"}} src={pepe} alt="pepe"/>

        </div> 

      </div>
    </Layout>
  )
};

export default Activity4;
