import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QrScanner from 'react-qr-scanner';

import scan_me from '../../../assets/scan_me.jpg';

const Reader = ({isScannerEnabled, setIsScannerEnabled}) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleScan = (data) => {
    if (data) {
      const scannedText = data.text; // Extract text from the QR code
      setIsScannerEnabled(false);

      console.log(scannedText)

      // Validate the URL format
      const regex = /^https:\/\/prohibidonojugar\.ciudaddemendoza\.gov\.ar\/actividad\/\d+(\/alerta)?$/;
      if (regex.test(scannedText)) {
        // Redirect to the valid URL path
        const urlPath = new URL(scannedText).pathname; // Extract the path from the URL
        navigate(urlPath); // Use react-router-dom for redirection
      } else {
        setErrorMessage('El código escaneado es inválido.');
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setErrorMessage('The scanned QR code does not match the required URL format.');
  };

  const stopScanner = () => {
    setIsScannerEnabled(false);
    setErrorMessage(null);
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>

      {isScannerEnabled ? (

        <>
          <QrScanner
            delay={300}
            onScan={handleScan}
            onError={handleError}
            style={{ width: '100%' }}
            constraints={{
              video: {
                facingMode: 'environment',
              },
            }}
          />
          <button onClick={stopScanner} className='code-cancel'> Cancelar </button>
        </>
      ):(
        <>
          {errorMessage && <p className='code_error'>{errorMessage}</p>}
          <img 
            onClick={() => {
              setErrorMessage(null);
              setIsScannerEnabled(!isScannerEnabled);
            }}
            style={{width:"150px", height:"150px", borderRadius:"10px"}} 
            src={scan_me}
            alt="escanear qr"
          />
        </>
      )}
      
    </div>
  );
};

export default Reader;
