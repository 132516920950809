import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const generateRoutes = (routes, user) =>
  routes.map(({ path, element }) =>
    user ? (
      <Route
        key={path}
        path={path}
        element={element}
      />
    ) : (
      <Route
        key={path}
        path={path}
        element={<Navigate to="/" />}
      />
    )
  );


export default generateRoutes
