// import { useNavigate } from 'react-router-dom';
// import { useState, useEffect, useRef } from 'react';
// import { gsap } from 'gsap';

import React, { useContext } from "react"
import { useNavigate } from 'react-router-dom';

import { Context } from "../../context";
import { mapsImages } from './assets';

import './Mapa.css';


function Mapa() {

  const { currentStep } = useContext(Context);
  const navigate = useNavigate();
  const map = mapsImages[`map_${currentStep}`] || mapsImages['complete']

  return (
    <div className='map mt-2'>

      <img style={{width:"100%"}} src={map} className="map-img" alt="mapa"/>
      <div className="close-btn" onClick={() => navigate(-1)}>&#10006;</div>

    </div>
  );
}

export default Mapa;
