import React, { useState, useRef } from "react"

import Layout from "../layout"
import CircularProgress from "./CircularProgress";
import number_5 from '../../../assets/number_5.png';
import fox from '../../../assets/activities/activity5/fox.png';
import audio_file from '../../../assets/activities/activity5/audio.mp3';
import play_btn from '../../../assets/activities/activity5/play_button.png';
import replay_btn from '../../../assets/activities/activity5/replay_button.png';

import './Activity5.css';

function Activity5Detail() {
  const [ firstStep, setFirstStep ] = useState(true)
  const [ isPlaying, setisPlaying ] = useState(false)

  const audioRef = useRef(null);

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error('Autoplay failed:', error);
      });
    }
    setisPlaying(true)
  }

  const handleRestart = () => {
    setFirstStep(true)
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        console.error('Playback failed:', error);
      });
    }
  };

  return (
    <Layout>
      <div className="activity activity-5 activity-5-detail">

        <img className="activity-number" src={number_5} alt="number_5"/>

        <audio ref={audioRef}>
          <source src={audio_file} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>

        <div className="text-info">
          
            { firstStep ?
            
              <>
                <p>
                ─Ahora, me voy a presentar yo ─dijo el Zorro gris, hablando a tanta velocidad que parecía que lo estaban persiguiendo─. Los que me conocen dicen que soy rápido y astuto. Me gusta comer ratones, cuises, carroña, frutos y semillas ─expuso en un tono serio─. Mi pelaje es muy valorado por los cazadores,  pero realmente me destaco por llevar semillas a otros sitios mediante mi materia fecal.
                </p>
                <div className="row justify-content-between align-items-end mt-1">
                  <img className="fox" src={fox} alt="fox"/>
                  <div>
                    { 
                      isPlaying 
                      ? <CircularProgress setFirstStep={setFirstStep} size={90}/>
                      : <img onClick={handlePlay} style={{width:"100px" }} src={play_btn} alt="play-btn"/>
                    }
                  </div>
                  
                </div>
              </> 
              : 
              <>
                <p>
                  Se podría decir que, gracias a mí, nuevos seres como el Algarrobo, germinan y comienzan a crecer por estos lugares ─informó orgullosamente con su hocico fino olisqueando el viento. Se paró, caminó unos pasos y volvió a sentarse para continuar─. Soy muy valiente, aunque algunas veces me asusten los perros que traen las personas y dejan sueltos sin sus correas.
                  ─Recuerdo muy bien ─contó con voz de sabio, Pepe Pedemonte─. Era una tarde muy tranquila, pocas personas transitaban por este sendero y escuché el asombro y la felicidad de una familia que pudo ver a una pareja de zorros que pasó muy cerca de ellos. ¡Fue hermoso! Ah, si quieren pueden escuchar mi playlist con los ojos bien cerrados, en aquel banco debajo de la sombra del árbol Aguaribay y dejarse llevar por la biodiversidad que habita en mi…
                </p>
                <div className="row justify-content-end">
                  <div className="audio-box">
                    <img onClick={handleRestart} style={{width:"80px", marginTop:"2rem", marginRight:"1.5rem"}} className="restart-btn" src={replay_btn} alt="restart-btn"/>
                  </div>
                </div>
              </>
            }

           
        </div>

      </div>
    </Layout>
  )
};

export default Activity5Detail;
