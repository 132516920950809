import React from "react"

import pepe from '../../../assets/pepe.png';
import useBodyColor from '../../../hooks/useColors';
import Navbar from "../navbar/Navbar"

import './skip.css';


function Skip() {
  useBodyColor(8)

  return (
    <>
      <Navbar/>
      <div className="skip column">
        <h2 className="text-white">Decidiste finalizar aquí la aventura. </h2>
        <h1 className="text-yellow">¡GRACIAS POR PARTICIPAR!</h1>

        <div className="row align-items-center justify-content-evenly mt-2">
          <div className="text-box">
            <p className="text-blue">VOLVÉ PRONTO y seguimos jugando</p>
          </div>
          <img className="pepe-img" src={pepe} alt="pepe"/>
        </div>
      </div>
    </>
  )
};

export default Skip;