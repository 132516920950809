import React from "react"

import Layout from "../layout"
import pepe from '../../../assets/pepe.png';


import './Activity7.css';

function Activity7() {
  return (
    <Layout>
      <div className="activity activity-7">

        <div className="text-info">
          <p>
            En estas conversaciones, el sol fue bajando con lentitud en el oeste, las sombras se extendían hacia el este desde donde se preparaba la Luna para iluminar la noche, y Pepe Pedemonte habló así:
            ─Estos son algunos de mis amigos que forman parte de la biodiversidad del Parque. Cada uno hace su camino, pero también es parte del camino del otro. <strong> El valor y la función de cada quién es irreemplazable y junto a las personas conformamos el ambiente que, cuando se construye con armonía, nos beneficia a todos y a todas. </strong>
            Cada uno de nosotros tenemos una misión de cuidado que cumplir y por eso era importante que todos y todas la escucharan ─Pepe tosió un poco porque empezaba a posarse el fresco nocturno sobre todo el Parque como un manto─. Esperó a que cada uno se vaya a su guarida o refugio. Sonreía al comprobar que su legado seguirá vivo a través de las generaciones..
          </p>
          <img className="pepe-img" src={pepe} alt="pepe"/>

        </div> 

      </div>
    </Layout>
  )
};

export default Activity7;
