import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles.css';
import App from './components/app'
import { ContextProvider} from "./context";

const root = ReactDOM.createRoot(document.getElementById('root'));
const user = localStorage.getItem('user');

root.render(
  <React.StrictMode>
    <ContextProvider user={user}>
      <App/>
    </ContextProvider>
  </React.StrictMode>
);