import React from "react"

import Layout from "../layout"
import number_6 from '../../../assets/number_6.png';
import siete_cuchillos from '../../../assets/activities/activity6/siete_cuchillos.png';
import colas from '../../../assets/activities/activity6/colas.png';

import './Activity6.css';

function Activity6() {

  return (
    <Layout>
      <div className="activity activity-6">

        <div style={{display:"flex"}}>
          <img className="activity-number" src={number_6} alt="number_6"/>
          <img style={{width:"250px", paddingRight:"1rem"}} src={siete_cuchillos} alt="imagen siete cuchillos"/>
        </div>

        <div className="text-info text-center">

          <p className="mt-2">
            <strong>
              Si prestás atención a la forma de su cola y al color de su plumaje, puede ayudarte a identificar de qué especie se trata.
            </strong>
          </p>

          <img className="mt-2" style={{width:"270px"}} src={colas} alt="imagen colas"/>

        </div> 

      </div>
    </Layout>
  )
};

export default Activity6;
