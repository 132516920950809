import React, { useState, useContext, useEffect } from "react"

import Layout from "../layout"


import Plant from "./game/Plant";
import { Icons } from '../activity3/game/assets';
import { Context } from "../../../context";

import './Activity3.css';

function Activity3Detail() {

  const { setFooterVisible } = useContext(Context);
  const [ activePlant, setActivePlant ] = useState(null)

  const handlePlant = (n) => {
    setFooterVisible(false)
    setActivePlant(n)

    // Push the new state to the history stack
    window.history.pushState({ activePlant: n }, "", "");
  }

  const handleBack = () => {
    setFooterVisible(true)
    setActivePlant(null)
  }

  const handleOK = () => {
    setFooterVisible(true)
    setActivePlant(null)
  }


  useEffect(() => {
    const onPopState = (event) => {
      // Handle state from history
      if (event.state && event.state.activePlant != null) {
        setFooterVisible(false);
        setActivePlant(event.state.activePlant);
      } else {
        setFooterVisible(true);
        setActivePlant(null);
      }
    };

    // Add popstate event listener
    window.addEventListener("popstate", onPopState);

    return () => {
      // Cleanup event listener
      window.removeEventListener("popstate", onPopState);
    };
  }, [setFooterVisible]);


  return (
    <Layout>
      <div className="activity activity-3 activity-3-detail">


        { activePlant == null ? (

          <>
            <h1 className="text-center">¡Encontralas todas!</h1>
            <div className="grid-container">

              { Icons.map((wonder, i) => (
                <div key={i} className="grid-item" onClick={() => handlePlant(i)}>
                  <img src={wonder} alt={`wonder${i + 1}`}/>
                </div>
              ))}

            </div>
          </>

        )
        :
        (
          <>
            <div className="text-info text-center">

              <Plant index={activePlant}/>

              <div className="row justify-content-evenly">
                <div className="btn-wonders" onClick={() => handleBack()}><span>No</span></div>
                <div className="btn-wonders" onClick={() => handleOK()}><span>Si</span></div>
              </div>
            </div>

          </>
        )}

      </div>
    </Layout>
  )
};

export default Activity3Detail;
