import btn_1 from '../../assets/home/btn-activity-1.png';
import btn_2 from '../../assets/home/btn-activity-2.png';
import btn_3 from '../../assets/home/btn-activity-3.png';
import btn_4 from '../../assets/home/btn-activity-4.png';
import btn_5 from '../../assets/home/btn-activity-5.png';
import btn_6 from '../../assets/home/btn-activity-6.png';
import btn_7 from '../../assets/home/btn-activity-7.png';


export const buttons = [
    { id: 1, image: btn_1, route: "/actividad/1" },
    { id: 2, image: btn_2, route: "/actividad/2" },
    { id: 3, image: btn_3, route: "/actividad/3" },
    { id: 4, image: btn_4, route: "/actividad/4" },
    { id: 5, image: btn_5, route: "/actividad/5/alerta" },
    { id: 6, image: btn_6, route: "/actividad/6" },
    { id: 7, image: btn_7, route: "/actividad/7" },
];