import { useNavigate, useLocation } from 'react-router-dom';


function Footer() {
  // Este componente crea los botones del footer
  // de forma dinamica basado en la url

  const navigate = useNavigate();
  const location = useLocation();

  const getActivityNumber = () => {
    const match = location.pathname.match(/\/actividad\/(\d+)(\/descripcion)?$/);
    return match ? match[1] : null;
  };

  const activityNumber = getActivityNumber()

  const buttonConfigs = [
    { label: 'INICIO', path: '/', visibleOnRoutes: [`/omitir`] },
    { label: 'VOLVER', path: `/actividad/:activityNumber`, visibleOnRoutes: [`/actividad/:activityNumber/descripcion`] },
    { label: 'OMITIR', path: '/omitir', visibleOnRoutes: [`/actividad/:activityNumber`] },
    { label: 'FINALIZAR', path: `/continuar`, visibleOnRoutes: [`/actividad/:activityNumber/descripcion`, '/actividad/2'], excludeOnRoutes: [`/actividad/7/descripcion`]},
    { label: 'FINALIZAR', path: `/felicitaciones`, visibleOnRoutes: [`/actividad/7/descripcion`] },
    { 
      label: 'CONTINUAR', 
      path: `/actividad/:activityNumber/descripcion`, 
      visibleOnRoutes: [`/actividad/:activityNumber`], 
      excludeOnRoutes: [`/actividad/2`]
    },
  ];

  const replacePlaceholders = (route) => route.replace(':activityNumber', activityNumber);

  const filteredButtons = buttonConfigs.filter(button => {
    const resolvedVisibleRoutes = button.visibleOnRoutes.map(replacePlaceholders);
    const resolvedExcludeRoutes = (button.excludeOnRoutes || []).map(replacePlaceholders);

    return (
      resolvedVisibleRoutes.includes(location.pathname) &&
      !resolvedExcludeRoutes.includes(location.pathname)
    );
  });

  return (
    <div className="buttons">
      {filteredButtons.map((button) => {
        const path = replacePlaceholders(button.path)
        return <div
          key={path}
          className="btn text-white"
          onClick={() => navigate(path)}
        >
          <span>{button.label}</span>
        </div>
      })}
    </div>
  );
}

export default Footer;