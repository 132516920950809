import React, { useState, useContext, useEffect } from "react"

import Layout from "../layout"
import Wonder from "./wonders/Wonder";
import { wondersIcons, number_7 } from './wonders/assets';
import { Context } from "../../../context";

import './Activity7.css';


function Activity7Detail() {

  const { setFooterVisible } = useContext(Context);
  const [ activeWonder, setActiveWonder ] = useState(null)

  const handleWonder = (n) => {
    setFooterVisible(false)
    setActiveWonder(n)

    // Push the new state to the history stack
    window.history.pushState({ activeWonder: n }, "", "");
  }

  const handleBack = () => {
    setFooterVisible(true)
    setActiveWonder(null)
  }

  const handleOK = () => {
    setFooterVisible(true)
    setActiveWonder(null)
  }


  useEffect(() => {
    const onPopState = (event) => {

      if (event.state && event.state.activeWonder != null) {
        setFooterVisible(false);
        setActiveWonder(event.state.activeWonder);
      } else {
        setFooterVisible(true);
        setActiveWonder(null);
      }
    };

    // Add popstate event listener
    window.addEventListener("popstate", onPopState);

    return () => {
      // Cleanup event listener
      window.removeEventListener("popstate", onPopState);
    };
  }, [setFooterVisible]);

  return (
    <Layout>
      <div className="activity activity-7 activity-7-detail">

        { activeWonder == null ? (

          <>
            <img className="activity-number" src={number_7} alt="number_7"/>
            <div className="text-info text-center">
              <h3 className="mt-1">SIETE MARAVILLAS</h3>
              <p className="mt-2">
                Te invitamos a jugar el juego de las 7 maravillas. Deberás encontrar a tu alrededor los siguientes lugares y seres vivos. Todos ellos forman parte de nuestro ambiente
              </p>
            </div>

              <div className="grid-container">

              { wondersIcons.map((wonder, i) => (
                <div key={i} className="grid-item" onClick={() => handleWonder(i)}>
                  <img src={wonder} alt={`wonder${i + 1}`}/>
                </div>
              ))}

            </div>
          </>

        )
        :
        (
          <>
            <div className="text-info text-center">

              <Wonder index={activeWonder}/>

              <div className="row justify-content-evenly">
                <div className="btn-wonders" onClick={() => handleBack()}><span>Buscar otra</span></div>
                <div className="btn-wonders" onClick={() => handleOK()}><span>Lo encontré</span></div>
              </div>
            </div>

          </>
        )}

      </div>
    </Layout>
  )
};

export default Activity7Detail;
