import React from "react"

import Layout from "../layout"
import pepe from '../../../assets/pepe.png';
import './Activity1.css';

function Activity1() {
  return (
    <Layout>
      <div className="activity activity-1">
        <div className="text-info text-center">
          <h2>HOLA, BIENVENIDAS Y BIENVENIDOS A LA ESTACIÓN 1</h2>
          <br/>
          <p>
            ¿Estás listo para empezar el juego con <strong>Pepe Pedemonte</strong> y sus amigos?
          </p>
          <img className="pepe-img mt-2" src={pepe} alt="pepe"/>
        </div> 
      </div>
    </Layout>
  )
};

export default Activity1;
