import Home from './components/home/Home';
import Register from './components/register/Register';
import Welcome from './components/welcome/Welcome';
import Pepe from './components/pepe/Pepe';
import Mapa from './components/mapa/Mapa';

import About from './components/activities/about/About';
import Skip from './components/activities/skip/Skip';
import Continue from './components/activities/continue/Continue';
import Activity1 from './components/activities/activity1/Activity1';
import Activity2 from './components/activities/activity2/Activity2';
import Activity3 from './components/activities/activity3/Activity3';
import Activity4 from './components/activities/activity4/Activity4';
import Activity5 from './components/activities/activity5/Activity5';
import Activity6 from './components/activities/activity6/Activity6';
import Activity7 from './components/activities/activity7/Activity7';

import Activity1Detail from './components/activities/activity1/Activity1Detail';
import Activity3Detail from './components/activities/activity3/Activity3Detail';
import Activity4Detail from './components/activities/activity4/Activity4Detail';
import Activity5Detail from './components/activities/activity5/Activity5Detail';
import Activity6Detail from './components/activities/activity6/Activity6Detail';
import Activity7Detail from './components/activities/activity7/Activity7Detail';

import Activity5Alert from './components/activities/activity5/Activity5Alert';
import Final from './components/activities/activity7/Final';

const routes = {
    public: [
      { path: "/", element: <Home /> },
      { path: "/register", element: <Register /> },
      { path: "*", element: <>404</> }
      
    ],
    private: [
      { path: "/bienvenida", element: <Welcome /> },
      { path: "/objetivos", element: <About /> },
      { path: "/introduccion", element: <Pepe /> },
      { path: "/mapa", element: <Mapa /> },
      { path: "/omitir", element: <Skip /> },
      { path: "/continuar", element: <Continue /> },
      { path: "/actividad/1", element: <Activity1 /> },
      { path: "/actividad/1/descripcion", element: <Activity1Detail /> },
      { path: "/actividad/2", element: <Activity2 /> },
      { path: "/actividad/3", element: <Activity3 /> },
      { path: "/actividad/3/descripcion", element: <Activity3Detail /> },
      { path: "/actividad/4", element: <Activity4 /> },
      { path: "/actividad/4/descripcion", element: <Activity4Detail /> },
      { path: "/actividad/5", element: <Activity5 /> },
      { path: "/actividad/5/descripcion", element: <Activity5Detail /> },
      { path: "/actividad/5/alerta", element: <Activity5Alert /> },
      { path: "/actividad/6", element: <Activity6 /> },
      { path: "/actividad/6/descripcion", element: <Activity6Detail /> },
      { path: "/actividad/7", element: <Activity7 /> },
      { path: "/actividad/7/descripcion", element: <Activity7Detail /> },
      { path: "/felicitaciones", element: <Final /> },
      { path: "*", element: <>404</> }
    ],
  };
  
export default routes