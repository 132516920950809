import { useNavigate } from 'react-router-dom';

import imagotipo from '../../assets/imagotipo.png';
import pepe from '../../assets/pepe.png';
import pepe_historias from '../../assets/intro/pepe_historias.png';
import useBodyColor from '../../hooks/useColors'
import './Pepe.css';


function Pepe() {
  const navigate = useNavigate();
  useBodyColor(0)

  return (
    <div className="pepe container bg-brown">
      <img className="imagotipo" src={imagotipo} alt="imagotipo"/>

      <img style={{width: "280px"}} src={pepe_historias} alt="pepe-historias"/>
      <img style={{width: "250px"}} src={pepe} alt="pepe"/>

      <div className="buttons">
        <div style={{margin:"0 1.5rem"}} className="btn text-white" onClick={() => navigate('/objetivos')}>
          <span>Objetivo del juego</span>
        </div>
        <div style={{margin:"0 1.5rem"}} className="btn text-white" onClick={() => navigate('/actividad/1')}>
          <span>Comenzar</span>
        </div>
      </div>


    </div>
  );
}

export default Pepe;
