export const Data = [
    {
        title: "CHAÑAR BREA",
        subtitle: "(Parkinsonia praecox)",
        credits: "Foto: Ernesto Toniolo",
        index: 0
    },
    {
        title: "FALSA JARILLA",
        subtitle: "(Zuccagnia punctata)",
        credits: "Foto: Ernesto Toniolo",
        index: 1
    },
    {
        title: "ATAMISQUE",
        subtitle: "(Atamisquea emarginata)",
        credits: "Foto: Ernesto Toniolo",
        index: 2
    },
    {
        title: "LLAOLLÍN",
        subtitle: "(Lycium chilense)",
        credits: "Foto: Ernesto Toniolo",
        index: 3
    }
];