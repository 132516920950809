import React, { useState } from "react"

import fox from '../../../assets/fox.png';
import useBodyColor from '../../../hooks/useColors';
import Navbar from "../navbar/Navbar"
import Reader from "./Reader";

import './continue.css';


function Continue() {

    const [isScannerEnabled, setIsScannerEnabled] = useState(false);
    useBodyColor(7)

    return (
        <>
            <Navbar/>
            <div className="continuar column text-center">

                {!isScannerEnabled && (
                    <div style={{marginTop:"5rem"}}>
                        <div className="text-box">
                            <img className="fox-img" src={fox} alt="fox"/>
                            <p className="text-center text-blue fw-2 mt-1" style={{padding:"0 1.5rem"}}>
                                Seguí caminando hasta la próxima estación y desbloqueá una <strong>nueva aventura</strong>
                            </p>
                        </div>
                    </div>
                )}

                <Reader 
                    isScannerEnabled={isScannerEnabled}
                    setIsScannerEnabled={setIsScannerEnabled}
                />
            </div>
        </>
    )
};

export default Continue;
