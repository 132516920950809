import React from "react"

import Layout from "../layout"
import number_5 from '../../../assets/number_5.png';
import fox_sing from '../../../assets/activities/activity5/fox_sing.png';

import './Activity5.css';


function Activity5() {

  return (
    <Layout>
      <div className="activity activity-5">

        <div className="row justify-content-center mt-1">
          <img style={{margin:'auto', width:'80px'}} src={number_5} alt="number_5"/>
        </div>

        <div className="text-info">
          <h2 className="text-center">Llegó la hora de descansar</h2>
          <p style={{marginTop:"2rem"}}>
            Dejate sorprender por los sonidos de la naturaleza y las historias que susurra el Zorro gris.
          </p>

          <div className="row justify-content-end mt-3">
            <img style={{width: "200px"}} src={fox_sing} alt="fox_sing"/>
          </div>
        </div>



      </div>
    </Layout>
  )
};

export default Activity5;
