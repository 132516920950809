import number_7 from '../../../../assets/number_7.png';
import wonder_icon_1 from '../../../../assets/activities/activity7/wonder_icon_1.png';
import wonder_icon_2 from '../../../../assets/activities/activity7/wonder_icon_2.png';
import wonder_icon_3 from '../../../../assets/activities/activity7/wonder_icon_3.png';
import wonder_icon_4 from '../../../../assets/activities/activity7/wonder_icon_4.png';
import wonder_icon_5 from '../../../../assets/activities/activity7/wonder_icon_5.png';
import wonder_icon_6 from '../../../../assets/activities/activity7/wonder_icon_6.png';
import wonder_icon_7 from '../../../../assets/activities/activity7/wonder_icon_7.png';

import wonder_1 from '../../../../assets/activities/activity7/wonder_1.jpg';
import wonder_2 from '../../../../assets/activities/activity7/wonder_2.jpg';
import wonder_3 from '../../../../assets/activities/activity7/wonder_3.jpg';
import wonder_4 from '../../../../assets/activities/activity7/wonder_4.jpg';
import wonder_5 from '../../../../assets/activities/activity7/wonder_5.jpg';
import wonder_6 from '../../../../assets/activities/activity7/wonder_6.jpg';
import wonder_7 from '../../../../assets/activities/activity7/wonder_7.jpg';

export const wondersIcons = [
    wonder_icon_1,
    wonder_icon_2,
    wonder_icon_3,
    wonder_icon_4,
    wonder_icon_5,
    wonder_icon_6,
    wonder_icon_7
];

export const wondersImages = [
    wonder_1,
    wonder_2,
    wonder_3,
    wonder_4,
    wonder_5,
    wonder_6,
    wonder_7
]

export { number_7 };