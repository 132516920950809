import React from "react"

import Layout from "../layout"

import number_1 from '../../../assets/number_1.png';
import friends from '../../../assets/activities/activity1/friends.png'

import './Activity1.css';

function Activity1Detail() {
  return (
    <Layout>
      <div className="activity activity-1">

        <div className="row justify-content-center">
          <img className="activity-number mx-auto" src={number_1} alt="number_1"/>
        </div>

        <div className="text-center">
          <p className="mt-2" style={{margin:'1rem 2rem'}}>
            ES HORA DE EMPEZAR A JUGAR Y CONOCER A LOS AMIGOS DE PEPE
          </p>
          <p className="mt-2" >
            El próximo desafío está muy cerquita
          </p>

          <img src={friends} alt="friends" className="mt-2" style={{width:'340px'}}/>
        </div>
      </div>
    </Layout>
  )
};

export default Activity1Detail;
