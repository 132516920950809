import React, { useContext, useEffect } from "react"
import { useLocation } from 'react-router-dom';
import useBodyColor from '../../hooks/useColors';

import { Context } from "../../context";
import Footer from "./footer/Footer"
import Navbar from "./navbar/Navbar"


import './layout.css';


function Layout({ children }) {

  const location = useLocation();
  const { footerVisible, setCurrentStep } = useContext(Context);

  const getActivityNumber = () => {
    const match = location.pathname.match(/\/actividad\/(\d+)(\/descripcion)?$/);
    return match ? match[1] : null;
  };

  const step = getActivityNumber()
  useBodyColor(step);

  useEffect(() => { setCurrentStep(step) }, [setCurrentStep, step]);

  return (

    <>

      <Navbar/>
    
      <div className="layout">

        <div className="container">
          {children}
        </div>

        { footerVisible ? <Footer/> : null}

      </div>
    </>

  )
};

export default Layout;
