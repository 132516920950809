import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import imagotipo from '../../assets/imagotipo.png';
import arrow_next from '../../assets/arrow_next.png';
import logos_2 from '../../assets/intro/logos_2.png';

import useBodyColor from '../../hooks/useColors'
import { Context } from "../../context";
import './Welcome.css';

function Welcome() {
  const navigate = useNavigate();
  const { userName } = useContext(Context);
  useBodyColor(0)

  return (
    <div className="welcome container mt-3" >
      <img className="imagotipo" src={imagotipo} alt="imagotipo"/>

      <div className="btn btn-name text-blue">
        <span>{userName}</span>
      </div>

      <h1 className="text-brown">vamos</h1>
      <h2 className="text-brown text-center" style={{fontSize:"2.5rem"}}>A jugar con <br></br> Pepe Pedemonte</h2>

      <div className="btn-next">
        <img
          src={arrow_next} 
          alt="arrow_next"
          onClick={() => navigate("/introduccion")}
        />
      </div>

      <img style={{width:"320px", marginTop:"2rem"}} src={logos_2} alt="logos_2" />

      
    </div>
  );
}

export default Welcome;
