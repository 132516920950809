import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from "react"

import logo from '../../../assets/intro/logos_1.png';
import useBodyColor from '../../../hooks/useColors';
import Navbar from "../navbar/Navbar"
import { Context } from "../../../context";
import friends from '../../../assets/activities/activity1/friends.png'

import './Activity7.css';

function Final() {
    useBodyColor(7)
    const navigate = useNavigate();

    const { setCurrentStep } = useContext(Context);

    useEffect(() => { setCurrentStep('return') }, [setCurrentStep]);

    return (
        <>
            <Navbar/>
            <div className="final text-center">

                <img src={friends} alt="friends" className="mt-2" style={{width:'250px'}}/>


                <h1 className="text-blue" style={{fontSize:"2.8rem"}}>¡Felicitaciones!</h1>
                <h2 className="text-blue mt-1">COMPLETASTE LA AVENTURA</h2>
                <div style={{margin:"2rem 0", fontSize:"1.4rem"}}>
                    <p>
                      Seguí las instrucciones para el camino de regreso a la primera estación.
                    </p>
                </div>

                <div className="btn text-blue" onClick={() => navigate("/mapa")}>
                  <span>MAPA REGRESO</span>
                </div>

                <img src={logo} style={{maxWidth:"250px"}} className="mt-3" alt="logos entes"/>

            </div>
        </>
    )
};

export default Final;
