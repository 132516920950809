import React, { useContext } from "react"
import { useNavigate } from 'react-router-dom';

import logo from '../../../assets/logo.png';
import map_icon from '../../../assets/activities/map/map_icon.png';
import { Context } from "../../../context";

function Navbar() {
  const { userName } = useContext(Context);
  const navigate = useNavigate();

  return (
    <div className="header">
      <div className="row justify-content-between align-items-center">
        <img 
          onClick={() => navigate('/')}
          className="btn-logo" 
          src={logo} 
          alt="logo"
        />

        <div className="btn btn-name text-blue">
          <span>{userName}</span>
        </div>

        <img 
          onClick={() => navigate('/mapa')}
          className=""
          src={map_icon}
          alt="map_icon"
        />
      </div>
    </div>
  )
};

export default Navbar;
