import React from "react"

import Layout from "../layout"
import number_3 from '../../../assets/number_3.png';
import jarilla from '../../../assets/activities/activity3/jarilla_hello.png';

import './Activity3.css';

function Activity3() {

  return (
    <Layout>
      <div className="activity activity-3">

        <img className="activity-number" style={{marginRight:"1.5rem"}} src={number_3} alt="number_3"/>

        <div className="text-info">
          <p>
            Hola soy Jarilla y te propongo un juego.
          </p>
          <br></br>
          <p>
            Identificá a las siguientes especies de flora nativa: para eso te mostramos su silueta, nombre vulgar y científico.
          </p>
        </div> 
        <img className="jarilla-img mt-3" src={jarilla} alt="jarilla"/>



      </div>
    </Layout>
  )
};

export default Activity3;
