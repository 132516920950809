import React from "react"

import Layout from "../layout"
import picos from '../../../assets/activities/activity6/picos.png';

import './Activity6.css';

function Activity6Detail() {
  return (
    <Layout>
      <div className="activity activity-6 activity-6-detail">

        <div className="text-info text-center" style={{marginLeft:"2rem"}}>
          <img className="mt-2" style={{width:"220px"}} src={picos} alt="imagen picos"/>
          <p className="mt-1">
            <strong>¿Sabías que los picos tienen especial relación con los alimentos que suelen comer?</strong>
          </p>
          <p className="mt-1">
            Por ejemplo, si son cortos y gruesos, permiten romper semillas, como en los loros. Si son cortos y delgados, permiten atrapar insectos que se mueven rápido. Si son largos, permiten tomar néctar de las flores, como en los colibríes. 
          </p>
        </div>

      </div>
    </Layout>
  )
};

export default Activity6Detail;
