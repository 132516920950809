import { useEffect } from 'react';

const useBodyColor = (step) => {

  useEffect(() => {

    let colorClass;
    switch (parseInt(step)) {
      case 1:
        colorClass = 'bg-color-1';
        break;
      case 2:
        colorClass = 'bg-color-2';
        break;
      case 3:
        colorClass = 'bg-color-3';
        break;
      case 4:
        colorClass = 'bg-color-4';
        break;
      case 5:
        colorClass = 'bg-color-5';
        break;
      case 6:
        colorClass = 'bg-color-6';
        break;
      case 7:
        colorClass = 'bg-color-7';
        break;
      case 8:
        colorClass = 'bg-color-8';
        break;
      default:
        colorClass = 'bg-default';
        break;
    }

    // Apply the color class to the body
    if (colorClass) {
      document.body.classList.add(colorClass);
    }

    // Cleanup: Remove the color class when the component unmounts
    return () => {
      if (colorClass) {
        document.body.classList.remove(colorClass);
      }
    };

  }, [step]);

};

export default useBodyColor;
