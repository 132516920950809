import React from "react"

import { wondersImages, wondersIcons } from './assets';
import { wondersData } from './constants';

function Wonder({ index }) {
  return (
    <div className="wonder">

      <img className="wonder-icon" src={wondersIcons[index]} alt={`wonder-${index}`}/>
      <p>{wondersData[index]["content"]}</p>

      <div>
        <img className="wonder-img" src={wondersImages[index]} alt={`wonder-${index}`}/>
        <p className="credits">{wondersData[index]["credits"]}</p>
      </div>

    </div>
  )
};

export default Wonder;
