import React from "react"

import { Images } from './assets';
import { Data } from './constants';

function Plant({ index }) {
  return (
    <div className="plant">

      <h4 style={{fontSize:"1.5rem"}}>¿Pudiste identificar alguna de las plantas del desafío?</h4>
      <h2 className="mt-2">{Data[index]["title"]}</h2>
      <h3>{Data[index]["subtitle"]}</h3>
      <div>
        <img className="plant-img" src={Images[index]} alt={`plant-${index}`}/>
      </div>
      <p className="plant-credit">{Data[index]["credits"]}</p>

    </div>
  )
};

export default Plant;
