import React, { createContext, useState, useEffect } from "react";

export const Context = createContext();

export const ContextProvider = ({ user, children }) => {
  // FOOTER
  const [footerVisible, setFooterVisible] = useState(true);

  // USER
  const [userName, setUserName] = useState(user);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser !== userName) {
      setUserName(storedUser);
    }
  }, [userName]);


  // CURRENT ACTIVITY
  const [currentStep, setCurrentStep] = useState(() => {
    const savedStep = localStorage.getItem('current_step');
    return savedStep && Number.isInteger(savedStep) ? parseInt(savedStep) : 'complete';
  });

  useEffect(() => {
      if (currentStep) {
          localStorage.setItem('current_step', currentStep);
      }
  }, [currentStep]);

  return (
    <Context.Provider value={{ 
      footerVisible, setFooterVisible,
      userName, setUserName,
      currentStep, setCurrentStep
    }}>
      {children}
    </Context.Provider>
  );
  
};