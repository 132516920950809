import map_1 from '../../assets/activities/map/map_1.png';
import map_2 from '../../assets/activities/map/map_2.png';
import map_3 from '../../assets/activities/map/map_3.png';
import map_4 from '../../assets/activities/map/map_4.png';
import map_5 from '../../assets/activities/map/map_5.png';
import map_6 from '../../assets/activities/map/map_6.png';
import map_7 from '../../assets/activities/map/map_7.png';

import map_complete from '../../assets/activities/map/map_complete.png';
import map_return from '../../assets/activities/map/map_return.png';
import map_return_short from '../../assets/activities/map/map_return_short.png';


export const mapsImages = {
    'map_1': map_1,
    'map_2': map_2,
    'map_3': map_3,
    'map_4': map_4,
    'map_5': map_5,
    'map_6': map_6,
    'map_7': map_7,
    'map_complete': map_complete,
    'map_return': map_return,
    'map_return_short': map_return_short
}