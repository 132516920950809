import React from "react"

import Layout from "../layout"
import pedemonte from '../../../assets/activities/activity4/pedemonte.png';

import './Activity4.css';

function Activity4Detail() {
  return (
    <Layout>
      <div className="activity activity-4 activity-4-detail">

        <div className="text-info text-center">
          <img alt="pedemonte" src={pedemonte} style={{maxWidth:"100%"}}/>
          <p className="credits">Foto: Ernesto Toniolo</p>
          <p className="mt-2">
            El Pedemonte es la transición entre la montaña y la llanura. Posee un rol ecológico fundamental como el de reducir las inundaciones, estabilizar el suelo  y es hogar de la biodiversidad. En sus quebradas podemos observar la retención de agua, que hace que las plantas crezcan más verdes y existan árboles más grandes.
          </p>
        </div>
      </div>
    </Layout>
  )
};

export default Activity4Detail;
